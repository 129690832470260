<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <span class="title" v-if="lastBid"> {{ $t("detailIndexPlaceBid") }}</span>
        <h3 class="content" v-if="lastBid">
          {{ $t("popupAuctionDefectPriceOnceTheBid") }}
        </h3>
        <h3 class="title" v-if="purchBid">
          {{ $t("popupBuyPriceBuyNow") }}
        </h3>
        <h3 class="contentPrice " v-if="isguarant && showMessage">
          {{ $t("hasReservePrice") }}
        </h3>

        <div class="form">
          <div class="ti-in">
            <span class="titles" v-if="lastBid">{{text}}<span class="price"> {{price}}</span>{{unit}}</span>
            <!-- <span class="titles" v-if="purchBid ">{{text}}<span class="price">{{Nft_sku.buyoutPrice}}</span>{{unit}}</span> -->
             <h3 class="content mt-10" v-if="purchBid">
              {{ $t("DoNotAuction") }}
            </h3>
            
            <!-- <span class="minprice"> {{ buyoutPrice }} {{$walletUnit}}</span> -->
            <v-text-field
              ref="fixPrice"
              v-model="fixPrice"
              :rules="[ rules.Bid]"
              outlined
              :suffix="unit"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              @blur.native.capture="checkBalance()"
              v-if="!isFix"
            ></v-text-field>
            <v-text-field
              v-model="fixPrice"
              outlined
              :suffix="unit"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              readonly
              v-if="isFix"
            ></v-text-field>
          </div>
               <div class="realPrice">
          <span class="feePrice">Loopring fee <span class="priceContent">{{feePrice}} {{ Nft_sku.marketTokenType }}</span></span> 
          <span class="totalPrice ml-5">Total price <span class="priceContent">{{totalPrice}} {{ Nft_sku.marketTokenType }}</span></span> 
      </div>
          <div></div>
        </div>
      </div>
      <div class="footer">
        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button>

        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" submit @click="subCommit" v-else-if="!needToShowLink">
          {{ $t("popupTransferCommit") }}
        </button>
        <button class="subdefect" v-else-if="needToShowLink" disabled="disabled">
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>

        <span class="price">{{ $t("popupNewTransferBalance") }}：{{ amountFormat }} {{ Nft_sku.marketTokenType }} </span>
         <span class="price" v-if="lockValue >0"> L2 Wallet Frozen amount：{{ lockValue }} {{ Nft_sku.marketTokenType }} </span>

        <span v-if="needToShowLink" class="lrcLink"> {{ $t("insufficientBalance")}}
          <a target="_blank" :href="loopringLink" >{{ $t("exchangeNow")}}</a>
        </span>
        <span v-else class="lrcLink">
        </span> 
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
// import { orderPay,getMyBalance } from "../../keplr/wallet";
import PromptBox from "@/components/PromptBox.vue";
import {getFeePrice} from "../../utils/helper";
import api from "@/api";
export default {
  components: { PromptBox },
  data: function() {
	  return{
    open: true,
    fixPrice: "",
    isFix: false,
    userBalance: "",
    buyoutPrice: "",
    isShowFalse: false,
    falseTitle: "",
    text:'',
    unit:'',
    isBalance:false,
    isguarant:false,
    lastBid:false,
    purchBid:false,
    showMessage:false,
    isPay: false,
    price:'',
    isLoad:false,
    feePrice:'',
    totalPrice:'',
    needToShowLink:false,
    amountFormat:'',
    lockValue:'',
    userInfo:{},
    chainToken:'',
    lockHash:'',
    tradeCost:'',
    loopringLink:'',
    StorageId:'',
    rules: {
      // BidRules: (value) => !!value || "Place a bid is required.",
      Bid: (value) => {
		const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || this.$t("PleaseInPutBid");
      },
    },
	}
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch:{
    fixPrice(value){
      console.log("wxl -- e334",value)
         console.log("wxl --- checkBalance")
         if(value == ''){
           this.totalPrice =  this.maxDecimal( parseFloat(this.feePrice) + parseFloat(this.price)) 
         }else{
           this.totalPrice =  this.maxDecimal( parseFloat(this.feePrice) + parseFloat(value)) 
         }
      
    }

  },

  props: {
    auctionNo: {
      type: String,
    },
    Nft_sku: {
      type: Object,
    },
    buyType: {
      type: Number,
    },


  },
  async mounted() {
    this.isLoad = true;
    if(this.Nft_sku.guaranteedPrice != null)
    {
      this.isguarant = true
    }
    let text = this.$t("popupAuctionDefectPriceYouMust")

    // this.text = text.substring(0,text.length-3) + price + " "+"IRIS"
    let type = this.buyType;
    if(type == 0){
        this.lastBid =true
    }else{
            this.purchBid = true
       this.lastBid =false
    }
    this.isLoad = true
    this.isPay = true
    if(this.Nft_sku.makerType == 2)
    {

      // 获取交易成本费
    let res = await this.LRCHandler.getOrderFee(
          this.Nft_sku.contractAddress,
          this.Nft_sku.marketTokenType,
         
        );
      
      this.tradeCost = res.data.amounts[0].tradeCost;
      // this.lastBid =true
      // console.log("wwwwwwww",res)
      // this.price = this.toDecimal(this.Nft_sku.lastBidPrice*1.05)
      //  this.text = text.substring(0,text.length-3)
      //  this.unit =  " "+this.Nft_sku.marketTokenType
      //  this.showMessage = true;
      this.feePrice = this.maxDecimal(getFeePrice(this.price,this.chainToken,this.tradeCost).feePrice)
    this.totalPrice =  this.maxDecimal( parseFloat(this.price) + parseFloat(this.price))   

    }
    if (type == 0) {
      this.buyoutPrice = this.Nft_sku.lastBidPrice;
      // this.price = this.Nft_sku.lastBidPrice*1.05;
      this.price = parseFloat(Math.ceil((this.Nft_sku.lastBidPrice)*1.05*10000))/10000
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.lastBidPrice + " "+"IRIS"
      //this.fixPrice = this.buyoutPrice
      this.text = text.substring(0,text.length-3)
       this.unit =  " "+this.Nft_sku.marketTokenType
      this.isFix = false;
      this.showMessage = true;
        this.feePrice = this.maxDecimal(getFeePrice(this.price,this.chainToken,this.tradeCost).feePrice)
       console.log("wxl --- 1112222",this.tradeCost,this.feePrice)
      this.totalPrice =  this.maxDecimal( parseFloat(this.price) + parseFloat(this.feePrice)) 
      let price;
       price = Number(this.price);

    // let mount = localStorage.getItem("key_balance");
    this.chainToken = this.getChainTokenId(this.Nft_sku.marketTokenType);
    let mount = await this.LRCHandler.getUserBalances(this.chainToken);
  


    console.log("wxl --- amountFormat",mount)
    this.amountFormat = Number(this.maxDecimal(Number(mount.amountValue) - Number(mount.lockedValue)) ) 
    this.lockValue = Number(this.maxDecimal(mount.lockedValue)) 
    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    this.userBalance = mount;
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }
    } else {

      this.buyoutPrice = this.Nft_sku.buyoutPrice;
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.buyoutPrice + " "+"IRIS"
       this.text = text.substring(0,text.length-3)
       this.unit =  " "+this.Nft_sku.marketTokenType
       this.isFix = true;
      this.fixPrice = this.buyoutPrice;
      this.showMessage = false
        this.feePrice = this.maxDecimal(getFeePrice(this.fixPrice,this.chainToken,this.tradeCost).feePrice)
       console.log("wxl --- 1112222",this.tradeCost,this.feePrice)
    this.totalPrice =  this.maxDecimal( parseFloat(this.feePrice) + parseFloat(this.fixPrice)) 
    

    // let mount = localStorage.getItem("key_balance");
     this.chainToken = this.getChainTokenId(this.Nft_sku.marketTokenType);
    let mount = await this.LRCHandler.getUserBalances(this.chainToken);
     this.amountFormat = this.maxDecimal(Number(mount.amountValue) - Number(mount.lockedValue)) 
     this.lockValue = this.maxDecimal(mount.lockedValue)
    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    this.userBalance = mount;


    //价格
      //this.fixPrice = this.priceSecond;
        let price;
        price = Number(this.fixPrice);
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }

    }
    this.isLoad = false;
    if(parseFloat(this.fixPrice) > parseFloat(this.amountFormat) || parseFloat(this.price) > parseFloat(this.amountFormat) ){
      this.needToShowLink = true;
      this.loopringLink = "https://loopring.io/#/trade/lite/"+ this.Nft_sku.marketTokenType + "-ETH";
    }
      // 获取storageid 
     let StorageIdObj= await this.LRCHandler.getStorageId(this.chainToken);
      this.StorageId = StorageIdObj.orderId
  },
  methods: {
            toDecimal(x) { 
 var f = parseFloat(x); 
 if (isNaN(f)) { 
 return; 
 } 
 f = Math.round(x*100)/100; 
 return f; 
 },
 maxDecimal(number){
  return String(number).replace(/^(.*\..{4}).*$/, "$1")
},
    checkBalance(){
   
      if(parseFloat(this.fixPrice) > parseFloat(this.amountFormat.amountValue)){
      this.needToShowLink = true;
      this.loopringLink = "https://loopring.io/#/trade/lite/"+ this.Nft_sku.marketTokenType + "-ETH";
    }else{
       this.needToShowLink = false
    }

    },
    verify() {
      let fixVer = this.$refs.fixPrice.validate(true);
      !fixVer ? this.$refs.fixPrice.focus() : "";
      return fixVer;
    },
  
    //提交转送数据
    async subCommit() {
      if(this.lastBid)
      {
          if (!this.verify()) {
        return;
      }
      }
      this.isPay = true
      if (this.buyType == 0) {
        let fixPrice = Number(this.fixPrice);
     
        if (fixPrice <  this.price ) {

          this.isShowFalse = true;
          this.falseTitle = this.$t("LowerThanCurrentPrice");
          this.isPay = false
          return;
        }
		
		if (fixPrice>this.amountFormat) {
		
		  this.isShowFalse = true;
		  this.falseTitle = this.$t("NotEnoughFee");
		  this.isPay = false
		  return;
		}
		
		
      }
      try {
        // acountid
      	let user = await api.home.loopringUserInfo({
				address: this.$store.state.did,
			});
			if (user) {
				this.userInfo = user.data;
			}
          let resTokenId=await api.home.getNftTokenId({"owner":this.Nft_sku.marketOwner,"tokenAddress": this.Nft_sku.contractAddress,"nftId": this.Nft_sku.nftId});
          if(resTokenId==null||resTokenId.data==null){
          this.$toast("error", "invalid NFT");
          return ;   
        }

         let accInfo = sessionStorage.getItem("KEY_accInfo");
      let storageParams = {
        bidder:this.$store.state.did,
        storageId:this.StorageId,
        tokenId:this.chainToken
      }
      let storageId = await api.home.getStorageId(storageParams)
      console.log("wxl --- check storageId",storageId,storageId.data)
      let updateStorageId = storageId.data


        let signature = await this.LRCHandler.takerOrder(
          resTokenId.data,
          this.Nft_sku.nftData,
          "1",
          this.chainToken,
          this.fixPrice.toString(),
          getFeePrice(this.fixPrice,this.chainToken,this.tradeCost,'auction').tradeCostPrice,
          updateStorageId
        );
        if(signature){
        let isShowTip = localStorage.getItem("EVM_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
             this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
        }
          
        }
           if(!signature){
          this.$toast("error", "Signature request was rejected");
               this.isPay = false;
         return
    }
       
        let makerOrderJson = {
          "exchange": signature.exchange,
          "accountId": signature.accountId,
          "storageId": signature.storageId,
          "sellToken": signature.sellToken,
          "buyToken": signature.buyToken,
          "allOrNone": signature.allOrNone,
          "fillAmountBOrS": signature.fillAmountBOrS,
          "validUntil": signature.validUntil,
          "maxFeeBips": signature.maxFeeBips
        };
        let parms = {
          makerOwner:this.Nft_sku.owner,
          owner: this.$store.state.did,
          accountId: this.userInfo.accountId,
          nftAddress: this.Nft_sku.contractAddress,
          nftId: this.Nft_sku.nftId,
          takerOrderJson: JSON.stringify(makerOrderJson),
          signature: signature.eddsaSignature,
          amount: 1,
        }
       
        let result;
         if(this.buyType == 0){
            parms.bidPrice =  this.fixPrice;
            parms.bidder=this.$store.state.did,
            parms.auctionNo=this.auctionNo,
            parms.storageId = updateStorageId,
            parms.tokenId= this.chainToken
            let lockedUntil = this.Nft_sku.auctionEndTime/1000;
  await this.LRCHandler.Lock(this.userInfo.accountId,this.chainToken,lockedUntil,"bid",this.fixPrice.toString(),'lockBalance').then((lockRes)=>{
      console.log(lockRes.hash)
		 if(lockRes!=null&&lockRes.hash){
		 		  this.lockHash = lockRes.hash
				  parms.lockHash=lockRes.hash
		 	 }else{
		 		  this.$toast("error", lockRes.message);
		 	 }
			 
		 
	  	    
  })
  if(!this.lockHash){
  	   this.isPay = false
  	  return 
  }
  result =  await api.home.auctionBid(parms);         
      }else{
			parms.bidPrice =  this.fixPrice;
            result = await api.home.nftTrade(parms);
         }
        
        if (result.success == true) {
             this.$emit("getOpen", false)
             this.isPay = false
             this.$router.push({name:'Marketplace'})
            let title=''
              if(this.buyType == 1){
                  let t = this.$t("buySuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + this.Nft_sku.name + "】"
					 title=t.replace(reg,name).replace(regN," ");
                  
              }else{
                title = this.$t("Bid placed")
              }
             let isShowTip = localStorage.getItem("EVM_isShowTip_"+this.$store.state.did)
            if(isShowTip == 'true'){
                setTimeout(()=>{
                    this.$mtip({
                    title:title,
                  });
                },2000)
            }
            
        } else {
            this.$toast("error", this.$t(result.msg))
            this.isPay = false
        }
      } catch (e) {
        console.log("wxl --- 333",e)
        //this.$refs.promptBox.show(this.$t("putawayFailed"));
        this.$toast("error", this.$t("putawayFailed"))
        this.isPay = false
      }
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 52px;
    margin-right: 52px;

    .title {
      margin: 42px 0 0 0;
      width: 300px;
      height: 21px;
      font-weight: bold;;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      font-weight:bold
    }

    .content {
      margin: 10px 0 15px 0;
      width: 485px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
    .contentPrice{
      margin: -10px 0 20px 0;
      width: 485px;
      height: 62px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
      word-wrap: break-word;
      width: 100%;
    }

    .form {
      .ti-in {
        .titles {
          margin-top: 24px;
          width: 152px;
          height: 13px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 48px;
          letter-spacing: 0px;
          color: #270645;
        }
        .price{
          font-weight:bold

        }

        .minprice {
          width: 51px;
          height: 13px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }

        .textinput {
          //  width: 500px;
          height: 75px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#f8f6fd, #f8f6fd);
          background-blend-mode: normal, normal;
          border-radius: 5px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background:#1d42ff;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
     .subdefect {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin: 0 10%;

      .content {
        width: 90%;
        height: auto;
      }
      .form {
        .ti-in {
          width: 100%;
          .textinput {
            width: 100%;
          }
        }
      }
    }

    .footer {
      .sub {
        width: 80%;
      }
    }

    .contentPrice {
      height: unset;
    }
  }
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 200px;
    margin-top: 3px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




