<template>
  <div :class="{ detail: true, mobile: isMobile }">
    <div class="shelf">
      <div class="showcase mb-5">
        <div class="img-box">
          <img class="mainimg" v-if="!productPic && !videoPic" contain />
          <v-img
            v-else-if="!videoPic"
            class="mainimg"
            :src="productPic"
            @click="openLargeImg"
            alt=""
            contain
          />
          <video
            v-else
            class="mainimg"
            ref="video"
            autoplay
            controls
            controlslist="nodownload"
            contain
            loop
            muted
            :src="videoPic"
            @click="openLargeImg"
          ></video>
        </div>
        <div class="name">{{ NftName }}</div>
        <div class="description" v-html="valueobj.description"></div>
        <div class="title mt-7" v-if="PropertyList &&PropertyList.length>0">Properties</div>
        <div
          v-for="(item, index) in PropertyList"
          :key="index"
          class="d-flex flex-column"
        >
          <div class="d-flex flex-row">
            <div class="Properties">{{ item.trait_type }}</div>
            <div class="PropertiesValue ">{{ item.value }}</div>
          </div>
        </div>
        <div class="lincenseimg mt-4" v-if="valueobj.lincenseCode == 'CC0'">
          <img
            src="@/assets/icons/cc-zero.png"
            @mouseenter="mousedlincense()"
            @mouseleave="leavelincense()"
            alt=""
          />
          <div
            class="explainlincense d-flex flex-row align-center"
            v-if="isshowLincense"
          >
            <span
              >CC0 (aka CC Zero) is a public dedication tool, it allows reusers
              to distribute, remix, adapt, and build upon the owned NFT in any
              medium or format, with no conditions. This doesn't affect the
              loyalty protection of this NFT to its creator.
            </span>
          </div>
        </div>
      </div>
      <div class="trading-information">
        <div class="d-flex flex-lg-row justify-space-between">
          <div class="d-flex flex-row head">
            <Avatar
              :size="42"
              :did="valueobj.owner"
              :hash="valueobj.ownerPhoto"
              showAvatarInfo
            />
            <div class="ml-2 d-flex flex-center mt-lg-3 ownerName">
              Owned by<span class="name" @click="toOwnerPage">{{
                valueobj.ownerName
              }}</span>
            </div>
            <!-- <div style="position:absolute;margin-left:90px;margin-top:45px;z-index:1">
					<CreatorAvator  :did="valueobj.owner"  v-if="isShowOwner"/>
				</div> -->
          </div>
          <div class="mt-2 countdown d-flex flex-row">
            <img
              v-if="
                this.$store.state.did && valueobj.owner != this.$store.state.did
              "
              width="20px"
              height="20px"
              src="@/assets/icons/btn_message.png"
              alt=""
              @click="msgBtn"
            />
            <div style="display: inline-block" @click="favoriteBtn">
              <img
                v-if="!isStar"
                src="@/assets/icons/icon_favorite.png"
                alt=""
              />
              <img
                v-if="isStar"
                src="@/assets/icons/icon_favorite1.png"
                alt=""
              />
            </div>

            <div
              style="display: inline-block; position: relative"
              @click="likeBtn"
            >
              <img v-if="!isLike" src="@/assets/icons/icon_like.png" alt="" />
              <img v-if="isLike" src="@/assets/icons/icon_like1.png" alt="" />
              <span
                style="
                  display: inline-block;
                  position: absolute;
                  bottom: -9px;
                  left: -3px;
                  width: 26px;
                  text-align: center;
                  font-size: 9px;
                  font-weight: bold;
                "
              >
                {{ valueobj.likeCount }}
              </span>
            </div>

            <img src="@/assets/icons/icon_share.png" @click="shareBtn" alt="" />

            <div class="share" v-if="openShare">
              <div
                class="d-flex flex-row mt-lg-5 mt-5"
                @click="share('copyLink', '', '')"
              >
                <img
                  class="mx-5"
                  src="@/assets/icons/icon_IPFS.png"
                  alt="Copy Link"
                />
                <span class="ml-0" style="cursor: pointer">{{
                  $t("carddetailShareCopyLink")
                }}</span>
              </div>
              <div
                class="d-flex flex-row mt-lg-6 mt-6"
                @click="share('twitter', sharePath, '')"
              >
                <img
                  class="mx-5"
                  src="@/assets/icons/icon_twitter1.png"
                  alt="Tweet"
                />
                <span class="ml-0" style="cursor: pointer">Twitter</span>
              </div>
              <div
                class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4"
                @click="share('facebook', sharePath, '')"
              >
                <v-icon class="mx-lg-5 ml-5 icon" color="primary">
                  mdi-facebook
                </v-icon>
                <span class="mt-2 pr-7" style="cursor: pointer">Facebook</span>
              </div>
            </div>
            	<div class="showtip" v-if="
                this.$store.state.did && valueobj.owner != this.$store.state.did
              ">
										<img
										class="hovershow"
										src="@/assets/icons/icon_complaint.png"
										alt=""
										@click="nftPlaintBtn"
										/>
										<div class="box">{{ $t("saledetailReport") }}</div>
							</div>
          </div>
          <!-- <img v-if="valueobj.owner !== $store.state.did" class="msg mt-lg-1 mt-3 ml-lg-5 ml-16" width="25px"
						height="24px" src="@/assets/icons/btn_message.png" alt="" @click="msgBtn" /> -->
        </div>
        <!-- <Countdown class="mt-12" :value="valueobj"/> -->
        <div v-if="isLoading">
          <CurrentBid
            class="mt-6"
            @auctionChange="auctionStatus"
            :value="valueobj"
          />
        </div>
        <div class="d-flex flex-row" style="position: relative">
          <div>
            <div
              class="mt-7"
              v-if="
                valueobj.auctionStatus == '0' ||
                valueobj.auctionStatus == '1' ||
                valueobj.auctionStatus == '2'
              "
            >
              <v-btn
                v-if="isClick && valueobj.assetStatus != 2 "
                class="rd"
                style="font-size: 20px"
                @click="bidBtn"
                outlined
                rounded
              >
                {{ $t("detailIndexPlaceBid") }}
              </v-btn>
              <v-btn
                v-else
                class="nord"
                style="font-size: 20px"
                outlined
                rounded
              >
                {{ $t("detailIndexPlaceBid") }}
              </v-btn>
            </div>
            <div class="mt-7" v-else>
              <v-btn class="nord" style="font-size: 20px" outlined rounded
                >{{ $t("popupSaletotalSold") }}
              </v-btn>
            </div>
          </div>
          <img
            v-if="valueobj.guaranteedPrice"
            class="refresh mt-11 ml-4"
            width="20"
            height="20"
            src="@/assets/icons/icon_bd.png"
            @mouseenter="moused()"
            @mouseleave="leave()"
          />
          <div class="reserveContant" v-if="isShowRecerve">
            <div class="des">
              This NFT has a reserve price. Only the final bid exceeds the
              reserve price will be accepted, otherwise it will be automatically
              rejected and the bid amount will be unlocked.
            </div>
          </div>
        </div>

        <div
          class="mt-3 font-weight-bold"
          style="color: #766983;font-size: 14px;!important"
          v-if="valueobj.buyoutPrice != null"
        >
          {{ $t("buyoutPrice") }}: {{ valueobj.buyoutPrice }}
          {{ valueobj.marketTokenType }}
        </div>
        <div class="mt-2">
          <!-- 不能拍卖-->
          <div v-if="!isClick" style="display: inline-block" class="mobox">
            <v-btn
              v-if="valueobj.buyoutPrice != null"
              class="nord"
              rounded
              outlined
            >
              {{ $t("popupBuyPriceBuyNow") }}
            </v-btn>
          </div>
          <!-- 可以拍卖-->
          <div v-else style="display: inline-block" class="mobox">
            <div
              v-if="valueobj.buyoutPrice != null && valueobj.assetStatus != 2"
              class="btnbox"
              style="display: inline-block"
            >
              <v-btn class="rd" @click="bidBuyBtn" rounded outlined
                >{{ $t("popupBuyPriceBuyNow") }}
              </v-btn>
            </div>
          </div>
          <!-- <img
                   v-if="valueobj.buyoutPrice != 0"
                 class="pointer phone ml-4 mt-lg-0 mt-0 mb-lg-n2 mb-n2"
                 :src="phoneBtn"
                 alt="button"
                 width="20px"
                 height="31px"
                 @click="buyBtn"
               /> -->
        </div>
        <div
          class="collect"
          v-if="valueContractobj.name"
          @click="collectClick(valueobj.contract)"
        >
          <img class="img" :src="getImageIpfs(valueContractobj.imgUrl)" />
          <div class="info">
            <span class="dect">{{ valueContractobj.name }}</span>
            <span class="num">{{ valueContractobj.nftCount }} Items</span>
          </div>
        </div>
        <div class="creatname d-flex flex-center mt-lg-3">
          Created by
          <span class="name" @mouseenter="mousedCreate()">{{
            valueobj.creatorName || addressEllipsis(valueobj.owner)
          }}</span>
        </div>
        <div style="position: absolute; margin-left: 90px;z-index:2">
          <CreatorAvator :did="valueobj.creator" v-if="isShowAva" />
        </div>
		<!-- <div class=" creatname  d-flex flex-center">
			Contract <a :href="this.addressExploer" target="_blank" style="text-decoration: none;"><span class="contract">{{ this.Nft_denomid }}</span></a>
		
		</div> -->
    <div  class="d-flex flex-row " style="position: relative;">
					<div class="  d-flex flex-center createName" style="margin-top:-4px;">
					Contract <a :href="this.addressExploer" target="_blank" style="text-decoration: none;"><span class="contracts">{{  (this.Nft_denomid ).substr(0,10)+"..."+(this.Nft_denomid ).substr(-10)}}</span></a>	
				<img v-if="valueContractobj.contractStatus==1"  style="height:20px;width:20px;margin-left:10px; " src="@/assets/icons/officially_verified.png" alt=""  @mouseenter="mousedContract()" @mouseleave="leaveContract()"/>
				</div>
					
				
					 <div
           		 class="explaincontract d-flex flex-row align-center"
            		v-if="isshowContract"
         			 >
          		  <span
            			  >Officially Verified
           		 </span>
          </div>
					
				</div>
        <div
          class="d-flex flex-center mt-lg-2"
          style="font-size:14px;!important"
          v-if="valueobj.channel"
        >
          From {{ valueobj.channel }}
        </div>

        <div class="current-bid mb-2 d-flex flex-row mt-lg-3 mt-2">
          <img src="@/assets/icons/icon_IPFS.png" alt="" />
          <a class="ml-4 mt-1" :href="this.IpfsHub" target="_blank">{{
            $t("saledetailViewOnIpfs")
          }}</a>
        </div>
        <div class="current-bid mb-2 d-flex flex-row mt-lg-1 mt-1">
          <img src="@/assets/icons/icon_metadata.png" alt="" />
          <a class="ml-4 mt-1" :href="this.IpfsMetadata" target="_blank"
            >View Metadata</a
          >
        </div>
      </div>
    </div>
    <div class="mt-8 mb-lg-16">
      <div class="font-weight-bold" style="font-size: 25px">
        {{ $t("detailIndexProvenance") }}
      </div>
      <v-data-iterator
        v-if="desserts.length > 0"
        :items="desserts"
        no-data-text=""
      >
        <template v-slot:header>
          <div
            class="d-flex justify-space-around mb-1 mt-8 py-2 pl-lg-6 elevation-0"
            style="border: solid 1px #cccccc; height: 50px"
          >
            <div style="width: 40%; padding-left: 10px; font-weight: bold">
              {{ $t("detailIndexBidder") }}
            </div>
            <div style="width: 25%; font-weight: bold">
              {{ $t("detailIndexBid") }} ({{ $walletUnit }})
            </div>
            <div style="width: 30%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="d-flex justify-space-around mb-1 py-2 pl-lg-6 elevation-0"
            v-for="item in items"
            :key="item.address"
            style="border: solid 1px #cccccc; height: 50px"
          >
            <div style="width: 45%; padding-left: 10px">
              <v-avatar size="30">
                <v-img
                  v-if="
                    item.formavatarSrc == undefined ||
                    item.formavatarSrc == 'null' ||
                    item.formavatarSrc == ''
                  "
                  src="@/assets/default.png"
                  alt=""
                />
                <v-img v-else :src="item.formavatarSrc" :alt="item.name" />
              </v-avatar>
              <span class="ml-3" style="font-size: 14px">{{ item.name }}</span>
            </div>
            <div style="width: 25%; font-size: 14px; margin-top: 7px">
              {{ item.bid }}
            </div>
            <div
              style="width: 30%; font-size: 14px; margin-top: 7px"
              v-if="!isMobile"
            >
              {{ item.time }}
            </div>
            <div style="width: 30%; font-size: 14px" v-else>
              {{ item.time }}
            </div>
          </div>
        </template>
      </v-data-iterator>
      <v-data-iterator
        v-else
        :items="desserts"
        hide-default-footer
        no-data-text=""
      >
        <template v-slot:header>
          <div
            class="d-flex justify-space-around mb-1 mt-8 py-2 pl-lg-6 elevation-0"
            style="border: solid 1px #cccccc; height: 50px"
          >
            <div style="width: 40%; padding-left: 10px; font-weight: bold">
              {{ $t("detailIndexBidder") }}
            </div>
            <div style="width: 25%; font-weight: bold">
              {{ $t("detailIndexBid") }}
            </div>
            <div style="width: 30%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="d-flex justify-space-around mb-1 py-2 pl-lg-6 elevation-1"
            v-for="item in items"
            :key="item.address"
          >
            <div style="width: 45%; padding-left: 10px">
              <v-avatar size="30">
                <v-img
                  v-if="
                    item.formavatarSrc == undefined ||
                    item.formavatarSrc == 'null' ||
                    item.formavatarSrc == ''
                  "
                  src="@/assets/default.png"
                  alt=""
                />
                <v-img v-else :src="item.formavatarSrc" :alt="item.name" />
              </v-avatar>
              <span class="ml-3">{{ item.name }}</span>
            </div>
            <div style="width: 25%">{{ item.bid }}</div>
            <div style="width: 30%">{{ item.time }}</div>
          </div>
        </template>
      </v-data-iterator>
    </div>
    <div class="sale_card" v-if="valueList.length != 0">
      <div class="sale_tit">
        <Avatar
          :size="40"
          :did="valueobj.owner"
          :hash="valueobj.profilePhoto"
        />
        <span style="display: inline-block" class="mb-lg-n9">{{
          valueobj.issuerName
        }}</span>
        <img
          class="pointer rgh"
          :src="rightArrow"
          alt=""
          width="31px"
          height="31px"
          @click="toPage"
        />
      </div>
      <div class="cardbox">
        <CardList :list="valueList" type="other" />
      </div>
    </div>
    <v-dialog v-model="openpay">
      <pay @getOpen="OpenPay" :address="address" :price="price"></pay>
    </v-dialog>
    <v-dialog v-model="openbuy">
      <buy-price :buytype="2" :assetLevel="1" @getOpen="OpenBuy"></buy-price>
    </v-dialog>
    <v-dialog v-model="openbid">
      <auction-price
        v-if="openbid"
        :auctionNo="auctionNo"
        :Nft_sku="Nft_sku"
        :buyType="buyType"
        @getOpen="OpenBid"
      >
      </auction-price>
    </v-dialog>
    <v-dialog v-model="opennobid">
      <auction-defect-price @getOpen="OpenNoBid"></auction-defect-price>
    </v-dialog>
    <v-dialog v-model="nftComplaint">
      <nft-complaints
        @getOpen="openNftcComp"
        :Nft_sku="valueobj"
        complaintType="commonComplaint"
      ></nft-complaints>
    </v-dialog>
    <v-dialog fullscreen v-model="openLargemg">
      <large-img
        ref="largeDialog"
        v-if="openLargemg"
        :productPic="productPic"
        :videoPic="videoPic"
        @getOpen="Open"
      >
      </large-img>
    </v-dialog>
    <div
      v-if="openmsg"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 99"
    >
      <message @getOpen="OpenMsg" :currfriend="currfriend"></message>
    </div>
  </div>
</template>

<script lang="js">
	import phoneBtn from "@/assets/icons/icon_pay.png";
	import message from "@/components/popup/message.vue";
	import Avatar from "@/components/avatar/index";
	import Pay from "@/components/popup/pay.vue";
	//   import Countdown from "./components/Countdown.vue";
	import CurrentBid from "./components/CurrentBid.vue";
	import CardList from "@/components/workCard/CardList.vue";
	import BuyPrice from "@/components/popup/buyPrice.vue";
	import AuctionPrice from "@/components/popup/auctionPrice.vue";
	import AuctionDefectPrice from "@/components/popup/auctionDefectPrice.vue";
	import nftComplaints from "@/components/popup/nftComplaints.vue";
	import rightArrow from "@/assets/icons/icon_r.png";
	import LargeImg from "@/components/largeImg.vue";
	import CreatorAvator from "@/components/creatorAvatar/index";
  import logoimg from "@/assets/default.png";
  import { getSettingArr } from "@/utils/helper";
	import api from "@/api";
	import {
		share
	} from "@/utils";

	import {
		getFileSrc
	} from "@/utils/file";

	const WORK_KEY = "WORK";
	const AVATAR_KEY = "AVATAR";

	export default {
		name: "Detail",
		components: {
			
			message,
			Avatar,
			Pay,
			BuyPrice,
			AuctionPrice,
			AuctionDefectPrice,
			CurrentBid,
			CardList,
			nftComplaints,
			LargeImg,
			CreatorAvator
		},
		props: {},
		data: () => {
			return {
				logoimg,
				address: "",
				price: "",
				phoneBtn,
				rightArrow,
				openpay: false,
				openbuy: false,
				openbid: false,
				opennobid: false,
				nftComplaint: false,
				openLargemg: false,
				openmsg: false,
				isLike: false,
				isStar: false,
				openShare: false,
				auctionNo: "",
				videoPic: "",
				productPic: "",
				sharePath: "",
					addressExploer:"",
				IpfsMetadata:"",
				IpfsHub: "",
				NftName: "",
				buyType: "",
				PropertyList:[],
				headers: [{
						text: "Bidder ",
						align: "start",
						value: "name",
						sortable: false
					},
					{
						text: "Bid (IRIS)",
						value: "bid",
						sortable: false
					},
					{
						text: "Time",
						value: "time",
						sortable: false
					}
				],
				desserts: [],
				valueList: [],
				cards: [],
				valueobj: {},
				valueContractobj:{},
				currfriend: {},
				Nft_denomid: "",
				isLoading: false,
				isClick: false,
				isnNoPriveClick: false,
				owner:'',
				isShowRecerve:false,
				isShowAva:false,
				isShowOwner:false,
				isShowHover:false,
        isshowLincense:false,
        isshowContract:false
			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			}
		},
		filters: {
			emptystr: function(value) {
				if (!value || value.indexOf("NaN") >= 0) return " ";
				return value;
			}
		},
		// watch:{
		//   'valueobj.buyoutPrice': function (v) {
		//     if(v == '0'){
		//       this.isnNoPriveClick = true
		//     }else{
		//       this.isnNoPriveClick = false
		//     }
		//   },
		//   deep: true
		// },
		async mounted() {
			this.isShowCreate = sessionStorage.getItem("showCreate");
			this.isDeposit = sessionStorage.getItem("isDeposit");
			this.showNoRead();
			window.addEventListener("click", this.clickOther);
				let paramsInfo = {
				tokenAddress: this.$route.query.tokenAddress,
				nftId: this.$route.query.nftId,
				owner: this.$route.query.owner
			};
			let res = await api.home.nftTokenInfo(paramsInfo);
			if (res) {
				this.isLoading = true;
			}
			
		this.owner = res.data.owner

  
		if(this.$store.state.did == res.data.owner && res.data.buyoutPrice<res.data.lastBidPrice)
      {
        this.isClick = false
        
      }

	   

			this.auctionNo = res.data.auctionNo;
			this.isStar = res.data.follewed;
    		this.isLike = res.data.loved;
			this.Nft_sku = res.data;
			this.valueobj = res.data;
			this.PropertyList = JSON.parse(res.data.properties)
			if(this.PropertyList)
			this.PropertyList = this.PropertyList.filter((item)=>item.trait_type != '' && item.value != '')

			if(res.data.contract){
				this.valueContractobj = res.data.contract
			}
			this.Nft_denomid = res.data.contractAddress;
			this.NftName = res.data.name;
			this.IpfsHub = this.getImageIpfs(res.data.imgUrl);
			this.IpfsMetadata=this.getImageIpfs(res.data.metadataUrl);
		
		if ( res.data.contractAddress) {
	
			this.addressExploer="https://explorer.loopring.io/collections/"+ res.data.contractAddress.toLowerCase()
		
		}
			if(this.Nft_sku.likeCount == 0)
		 {
		   this.Nft_sku.likeCount = ''
		 } 
		

			this.valueobj.description = this.valueobj.description.replace(/\n/g, "<br/>");

			this.desserts = [];
			if (res.data.buyoutPrice == 0) {
				this.isShow = false;
			} else {
				this.isShow = true;
			}
			let res2 = await api.home.bidHistory({
					auctionNo:this.Nft_sku.auctionNo
				});
			this.desserts = [];

				res2.data.forEach(async (v) => {
					let from;
					let name = "";
					if(v.bidderProfilePhoto){
					 from = await getFileSrc(AVATAR_KEY, v.bidderProfilePhoto);
					} else{
						from = logoimg;
					}
					
					//let to = await getFileSrc(AVATAR_KEY, v.toImg);
					if (!v.bidderName) {
						
						name = v.bidder.substr(0, 6) + "..." + v.bidder.substr(-6);
					} else {
						name = v.bidderName;
					}
				
					this.desserts.push({
						name: name,
						bid: v.bidPrice,
						time: this.timestampToDateTime(v.createTime),
						formavatarSrc: from
					});
				
				});
		
			// this.valueList = res.data.relatedContractList

			// api.home.getNftDetailRela({
			// 	did: res.data.owner,
			// 	contractId: res.data.contractId
			// }).then(resn => {
			// 	this.valueList = resn.data.skuList;
			// });
			//获取大图/卡片
			if (this.valueobj.imgUrl !== null) {
				this.productPic = await getFileSrc(WORK_KEY, this.valueobj.imgUrl);
			}
			if(this.valueobj.ownerName==null){
				this.valueobj.ownerName=this.valueobj.owner.substr(0, 3) + "..." + this.valueobj.owner.substr(-3)
			}
		if(this.valueobj.createrName==null){
			this.valueobj.createrName=this.valueobj.creator.substr(0, 3) + "..." + this.valueobj.creator.substr(-3)
		}
		
			//获取头像
			// 是否收藏
			let params = {
				userAddress: this.$store.state.did,
				followId: this.Nft_sku.nftId,
				followType: "1"
			}
			let iscollect = await api.home.isCollect(params)
			if (iscollect.data == true) {
				this.isStar = true
			} else {
				this.isStar = false
			}
			params.followType = 2
			let islike = await api.home.isCollect(params)
			if (islike.data == true) {
				this.isLike = true
			} else {
				this.isLike = false
			}
			// 判断用户是否存在
      let param = {
          address: this.valueobj.creator,
        };
        let ress = await api.home.loopringUserInfo(param);
        if(ress.data.createTime == null){
          this.isShowHover = false
        }else{
          this.isShowHover = true
        }

		},
		methods: {
				toOwnerPage(){
			 this.$router.push({
          name: "Otherpage",
          query: { did: this.valueobj.owner },
        });
			},
			share,
			//添加收藏
		async favoriteBtn() {
				let params = {
					followType: 1,
					followId: this.Nft_sku.nftId,
					userAddress: this.$store.state.did,
					followOwner:this.Nft_sku.owner

				};
				let res = await api.home.followDo(params);
				if (res.success === true) this.isStar = !this.isStar;
			},
			// isleave(isleave){
			// 	console.log("wxl --- 2222",isleave)
			// 	if(isleave){
			// 		this.isShowAva = false
			// 	}else{
			// 		this.isShowAva = true
			// 	}

			// },
			mousedCreate(){
				if(this.isShowHover)
				this.isShowAva = true
			},
			mousedOwner(){
				this.isShowOwner = true
      },
      		 mousedContract(){
      this.isshowContract = true

    },
    leaveContract(){
      this.isshowContract = false
    },
			clickOther(){
				this.isShowAva = false
				this.isShowOwner = false
			},
			
			async likeBtn() {
				let params = {
					followType: 2,
					followId: this.Nft_sku.nftId,
					userAddress: this.$store.state.did,
					followOwner:this.Nft_sku.owner
				};
				let res = await api.home.followDo(params);
				if (res.success === true) {
					this.isLike = res.data;
					if (!this.isLike) {

						if (this.Nft_sku.likeCount <= 1) {
							this.Nft_sku.likeCount = 0
						} else {
							--this.Nft_sku.likeCount
						}
					} else {

						if (this.Nft_sku.likeCount == null) {
							this.Nft_sku.likeCount = 1
						} else {
							++this.Nft_sku.likeCount
						}

					}
					if (this.Nft_sku.likeCount == 0) {
						this.Nft_sku.likeCount = ''
					}
				}
			},
			shareBtn() {
				this.openShare = !this.openShare;
			},
			buyBtn() {
				this.openpay = true;
			},
			//弹框关闭后获取open值
			OpenPay(e) {
				this.openpay = e;
			},
			bidBtn() {

				// if (!this.$vuetify.breakpoint.mobile) {
					if (!this.$store.state.did) {
						window.eventBus.$emit("ShowLogoBox");
						return;
					}
				// }
				// if (this.$vuetify.breakpoint.mobile) {
				// 	this.openpay = true;
				// 	return;
				// }
				this.buyType = 0;
				this.openbid = true;
			},
			OpenBid(e) {
				this.openbid = e;
			},
			bidBuyBtn() {
				// if (!this.$vuetify.breakpoint.mobile) {
					if (!this.$store.state.did) {
						window.eventBus.$emit("ShowLogoBox");
						return;
					}
				// }
				// if (this.$vuetify.breakpoint.mobile) {
				// 	this.openpay = true;
				// 	return;
				// }
				this.buyType = 1;
				this.openbid = true;
			},
			nobidBtn() {
				this.opennobid = true;
			},
			OpenNoBid(e) {
				this.opennobid = e;
			},
			buyNowBtn() {

				if (this.$vuetify.breakpoint.mobile) {
					this.openpay = true;
					return;
				}
				this.openbuy = true;
			},
			OpenBuy(e) {
				this.openbuy = e;
			},
			nftPlaintBtn() {
				this.nftComplaint = true;
			},
			openNftcComp(e) {
				this.nftComplaint = e;
			},
			//查看大图
			openLargeImg() {
				this.openLargemg = true;
				// this.$refs.largeDialog.playVideo();
			},
			//弹框关闭后获取open值
			Open(e) {
				this.openLargemg = e;
			},
			msgBtn() {
				this.currfriend = {
					fansName: this.valueobj.issuerName,
					fansAddress: this.valueobj.owner
				};
				this.openmsg = true;
			},
			//弹框关闭后获取open值
			OpenMsg(e) {
				this.openmsg = e;
			},
			toPage() {
				this.$router.push({
					name: "Otherpage",
					query: {
						did: this.valueobj.owner
					}
				});
			},
			moused(){
				console.log("2222")
				this.isShowRecerve = true

			},
			leave(){
				console.log("1111")
				this.isShowRecerve = false
			},
			 mousedlincense(){
      			this.isshowLincense = true
			},
    leavelincense(){
      this.isshowLincense = false
    },
			timestampToTime(time) {
				let date = new Date(time);
				let Y = date.getFullYear() + "-";
				let M =
					(date.getMonth() + 1 < 10 ?
						"0" + (date.getMonth() + 1) :
						date.getMonth() + 1) + "-";
				let D =
					(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				let h =
					(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
				let m =
					(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
					":";
				let s =
					date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				let strDate = Y + M + D + h + m + s;
				return strDate;
			},
			timestamptitleTime(time) {
				let date = new Date(time);
				let Y = date.getFullYear() + ".";
				let M =
					(date.getMonth() + 1 < 10 ?
						"0" + (date.getMonth() + 1) :
						date.getMonth() + 1) + ".";
				let D =
					(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				// let h =
				//   (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
				// let m =
				//   (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
				//   ":";
				// let s =
				//   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				let strDate = Y + M + D;
				return strDate;
			},
			//拍卖未到时间，不可购买或竞价
			auctionStatus(e) {
				console.log("wxl --- auctionStatus",e)
				if(this.$store.state.did != this.owner)
			
				this.isClick = e;
				let isShowCreate = sessionStorage.getItem("showCreate");
				  let isDeposit = sessionStorage.getItem("isDeposit");
				  if(!isShowCreate || isDeposit != 3){
				 this.isClick=false
				}
			},
			collectClick(collectionInfo) {
				// this.$router.push({name: "CollectionPage", query: { tokenAddress: this.collectionInfo.address }});
				let url=""
				if(collectionInfo.linkUrl){
					url=collectionInfo.linkUrl
				}else{
					url=collectionInfo.address
				}
				let collectsource="detaillink"
				if (this.did == 2) {
					this.$router.push({
						path: `/collection_create/${url}${this.collectsource}/${collectionInfo.owner}`,
					});
				} else {
					this.$router.push({
						path: `/collection/${url}/${collectsource}/${collectionInfo.owner}`,
					});
				}
			},
			 async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
      let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContent,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
			
		}
	};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.explaincontract{
      width: 130px;
      height: 40px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 25px;
    right: 20px;
      z-index: 1;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }
    .contracts {
		  cursor: pointer;
		
		  font-family: Helvetica;
		  font-size: 14px;
		  font-weight: normal;
		  font-stretch: normal;
		  letter-spacing: 0px;
		  color: #1d42ff;
		  padding-left: 4px;
		}
.phone {
  width: 20px;
  height: 31px;
  display: inline-block;
}

.detail {
  letter-spacing: 0px;
  color: #270645;

  .shelf {
    display: flex;

    .showcase {
      width: 771px;

      .img-box {
        height: 611px;
        background-color: #f8f6fd;

        .mainimg {
          // width: 100%;
          // height: 100%;
          max-height: 611px;
          max-width: 100%;
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: contain;
          align-items: center;
          /*垂直居中*/
          justify-content: center;
          /*水平居中*/
          //    width: 458px;
          //    height: 610px;
          //    margin: 0 auto;
        }
      }

      .name {
        margin-top: 51px;
        // height: 43px;
        font-weight: bold;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .description {
        margin-top: 22px;
        font-size: 16px;
      }
      .lincenseimg {
        position: relative;
        img {
      
          height: 30px;
        }
        .explainlincense {
          width: 330px;
          height: 100px;
          background-color: #270645;
          border-radius: 3px;
          position: absolute;
          top: 40px;
        //   left: 25px;
          z-index: 1;
          span {
            margin: auto;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: 0px;
            color: #ffffff;
            margin-left: 13px;
            margin-right: 13px;
          }
        }
      }
      .title {
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .Properties {
		  width: 185px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
	   .PropertiesValue{
      font-family: Helvetica;
	    font-size: 15px;
	    font-weight: normal;
	    font-stretch: normal;
	    letter-spacing: 0px;
	    color: #270645;
    }
    }

    .trading-information {
      margin-left: 50px;
      flex: 1;
      .head {
        .ownerName {
          color: #766983;
          font-size: 14px;
          .name {
            cursor: pointer;
            width: 95px;
            height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;

            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #1d42ff;
            padding-left: 4px;
          }
        }
      }
      .collect {
        width: 252px;
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;

        .img {
          width: 61px;
          height: 61px;
          background-color: #270645;
          border-radius: 5px;
          object-fit: cover;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          background-color: unset !important;

          .name {
            font-weight: bold;
            font-size: 13px;
            line-height: 24px;
            color: #270645;

            font-weight: bold;
          }

          .dect {
            max-width: 195px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            line-height: 24px;
            color: #270645;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .num {
            font-family: Helvetica;
            font-size: 13px;
            line-height: 24px;
            color: #766983;
          }
        }
      }
      .creatname {
        font-family: Helvetica;
        font-size: 14px;
        color: #270645;
        .name {
          cursor: pointer;
          width: 120px;
          height: 30px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;

          font-family: Helvetica;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #1d42ff;
          padding-left: 4px;
        }
		.contract {
		  cursor: pointer;
		
		  font-family: Helvetica;
		  font-size: 14px;
		  font-weight: normal;
		  font-stretch: normal;
		  letter-spacing: 0px;
		  color: #1d42ff;
		  padding-left: 4px;
		}
      }
      .reserveContant {
        position: absolute;
        right: 70px;
        top: 70px;
        width: 212px;
        height: 121px;
        background-color: #270645;
        border-radius: 3px;
        z-index: 1;
        .des {
          margin: 15px;
          width: 183px;
          height: 71px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          line-height: 15px;
        }
      }
      .name {
        height: 17px;
        font-weight: bold;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .time {
        height: 10px;
        font-family: Helvetica;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #766983;
      }

      .mt-7 {
        .rd {
          width: 294px;
          height: 51px;
          // background-image: linear-gradient(90deg,
          // 		#d300fd 0%,
          // 		#a439fe 26%,
          // 		#7471ff 52%,
          // 		#00fdcf 100%),
          // 	linear-gradient(#270645, #270645);
          // background-blend-mode: normal, normal;
          background: #1d42ff;
          border-radius: 25px;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          /*line-height: 100px;*/
          letter-spacing: 0px;
          color: #ffffff;
          border: none;
        }

        .nord {
          width: 294px;
          height: 51px;
          background-image: linear-gradient(#766983, #766983),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal;
          border-radius: 25px;
          opacity: 0.9;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          /*line-height: 100px;*/
          letter-spacing: 0px;
          color: #ffffff !important;
        }
      }

      .mt-2 {
        .btnbox {
          width: 294px;
          height: 51px;
          box-sizing: border-box;
          padding: 2px;
          border: 2px solid #1d42ff;
          border-radius: 25px;

          .rd {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 25px;
            background: #fff;
            font-weight: bold;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0;
            color: #270645;
          }
        }

        .nord {
          width: 294px;
          height: 51px;
          background-image: linear-gradient(#766983, #766983),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal;
          border-radius: 25px;
          opacity: 0.9;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 100px;
          letter-spacing: 0px;
          color: #ffffff !important;
        }
      }

      .font-weight-bold {
        img {
          width: 31px !important;
          height: 31px !important;
          margin-right: 16px;
          vertical-align: middle;
        }
      }

      .countdown {
        position: relative;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }

        .share {
          width: 166px;
          height: 161px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#6f58d9, #6f58d9);
          background-blend-mode: normal, normal;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          position: absolute;
          left: -50px;
          top: 25px;
          z-index: 20000;

          img,
          .icon {
            display: inline-block;
            width: 25px;
            height: 25px;
          }

          span {
            font-weight: bold;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }

        .showtip {
          display: inline-block;
          position: relative;

          .box {
            position: absolute;
            left: -65px;
            top: 27px;
            margin-top: 0px;
            border-radius: 5px;
            background: #270645;
            width: 85px;
            height: 31px;
            color: #ffffff;
            font-size: 14px;
            line-height: 34px;
            text-align: center;

           
          }
        }

        .showtip > .box {
          display: none;
        }

        .showtip:hover > .box {
          display: block;
        }
      }

      .v-btn.rd {
        width: 294px;
        height: 51px;
      }

      .v-icon {
        font-size: 30px;
      }

      .current-bid {
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        margin-top: 25px;
        letter-spacing: 0px;
        color: #270645;

        a {
          text-decoration: none;
        }

        img {
          width: 31px !important;
          height: 31px !important;
          //  margin-right: 16px;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          font-weight: bold;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
  }

  .v-data-iterator {
    width: 770px;
  }

  .sale_card {
    // width: 1349px;
    height: auto;
    margin-bottom: 100px;

    .sale_tit {
      margin-bottom: 17px;
      position: relative;

      img {
        width: 42px;
        height: 42px;
      }

      span {
        display: inline-block;
        position: relative;
        top: 0px;
        left: 13px;
        font-weight: bold;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .rgh {
        position: absolute;
        right: 0;
      }
    }
  }

  &.mobile {
    .shelf {
      flex-direction: column;

      .showcase {
        width: 100%;

        .img-box {
          height: auto;
        }

        .name {
          margin-top: 20px;
        }

        .description {
          margin-top: 20px;
        }
      }

      .trading-information {
        margin-left: 0;
        .flex-lg-row {
          display: flex !important;
          flex-direction: column !important;
          .countdown {
            margin-top: 15px !important;
          }
        }
        .ownerName {
          align-items: center !important;
          .name {
            display: flex;
            align-items: center !important;
          }
        }
        .creatname {
          margin-top: 15px !important;
        }
        .name {
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .msg {
          height: 26px !important;
          width: 24px !important;
        }

        .mt-7 {
          width: calc(100% - 20px);

          .rd {
            // width: 100% !important;
          }

          .nord {
            // width: 100%;
          }
        }

        .mt-3 {
          width: 100%;

          .mobox {
            width: 100%;

            .btnbox {
              width: calc(100% - 20px);
              /*max-width: 280px;*/

              .rd {
                width: 100% !important;
              }
            }

            .nord {
              width: calc(100% - 20px);
            }
          }
        }

        .countdown {
          .box {
            position: absolute;
            right: 63px;
            margin-top: 5px;
          }

          .share {
            width: 166px !important;
            height: 161px;
            background-image: linear-gradient(#ffffff, #ffffff),
              linear-gradient(#6f58d9, #6f58d9);
            background-blend-mode: normal, normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            position: absolute;
            right: 100px;
            top: 30px;
            z-index: 1000000;

            img,
            .icon {
              display: inline-block;
              width: 25px;
              height: 25px;
            }

            span {
              font-weight: bold;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #270645;
            }
          }
        }

        .v-btn.rd {
          // width: calc(100% - 60px);
        }

        .v-icon {
          font-size: 30px;
        }
      }
    }

    .v-data-iterator {
      width: 100%;
    }

    .sale_card {
      width: 100%;

      .cardbox {
        width: 100%;
        margin-bottom: 38px;
      }
    }
  }
}
</style>
